<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting" :style="`--var-progress-color: ${selectedPlaybook.progressbar_color ? selectedPlaybook.progressbar_color : '#2f7eed'}`">
            <button class="close_btn modal-window-close-button" @click="closeModal"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-setting-form" class="playbook-setting-form">
                    <div class="content_area">
                        <ul class="btn_list">
                            <li v-if="toggleAll" @click="openAllSection">Open All</li>
                            <li v-else @click="closeAllSection">Close All</li>
                        </ul>
                        <div class="content_wpr">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>General</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Portal URL</label>
                                                    <div class="field_wpr has_suffix">
                                                        <Field autocomplete="off" type="text" v-model="form.portal_url" name="portal_url" />
                                                        <span class="suffix pointer" @click="handleCopy(form.portal_url)">Copy</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Playbook Name</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                                        <Field autocomplete="off" type="text" name="title" v-model="form.title" placeholder="ex: New Playbook" />
                                                    </div>
                                                    <ErrorMessage name="title" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Playbook Description</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                                        <Field autocomplete="off" name="description" v-model="form.description">
                                                            <textarea name="description" id="description" v-model="form.description" cols="10" rows="10" placeholder="Description Goes Here.."></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="description" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Playbook Categories <a class="option" @click="createCategory = !createCategory">Create Smart Category</a></label>
                                                    <div class="field_wpr">
                                                        <multiselect
                                                            v-model="form.categories"
                                                            :options="playbookCategories"
                                                            :searchable="true"
                                                            valueProp="id"
                                                            label="name"
                                                            placeholder="Select Category"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="createCategory" class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Category Name</label>
                                                    <Form @submit="handleCreateCategory" v-slot="{ errors }" ref="category-form">
                                                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                            <Field autocomplete="off" type="text" name="name" v-model="categoryForm.name" placeholder="Enter Category Name" rules="required" />
                                                            <span class="suffix" @click="handleCreateCategory()"><i class="fa fa-spin fa-spinner" v-if="categorySaveLoader"></i> {{ categorySaveLoader ? 'Creating' : 'Create' }}</span>
                                                        </div>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </Form>
                                                </div>
                                            </div>
                                            <div class="capsule_elm pt-2">
                                                <h5>Apply Saved Styling?</h5>
                                                <label for="apply_saved_styling" class="switch_option capsule_btn border-0">
                                                    <input type="checkbox" id="apply_saved_styling" :true-value="1" :false-value="0" v-model="form.apply_saved_styling" @change="handleApplySavedStylingWarning()" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container mt-1 mb-2">
                                        <h3 class="sub_heading2">Step Settings</h3>
                                        <div class="capsule_elm">
                                            <h5>Show Step Thumbnails</h5>
                                            <label for="step_thumbnail" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="step_thumbnail" :true-value="1" :false-value="0" v-model="form.has_step_thumb" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <template v-if="form.has_step_thumb">
                                            <div class="capsule_elm">
                                                <h5>Use default step thumbnail</h5>
                                                <label for="has_default_step_thumbnail" class="switch_option capsule_btn border-0">
                                                    <input type="checkbox" id="has_default_step_thumbnail" :true-value="1" :false-value="0" v-model="form.has_default_step_thumbnail" @change="handleDefaultThumbWarning()" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <image-library v-if="form.has_default_step_thumbnail" v-model="form.default_step_thumbnail" image-type="playbook-step-thumb" upload-text="Thumbnail" :handle-image="handleStepThumbUpdate" />
                                        </template>
                                    </div>
                                    <div class="color_container mt-1">
                                        <div class="capsule_elm">
                                            <h5>Ordered Completion Required</h5>
                                            <label for="order_completion" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="order_completion" :true-value="1" :false-value="0" v-model="form.must_be_completed" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="setting_wpr" v-if="form.must_be_completed">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Locked Step BG Color</label>
                                                    <Field autocomplete="off" name="step_locked_bg" v-model="form.step_locked_bg" type="text" label="element color">
                                                        <color-picker v-model="form.step_locked_bg" :classes="{ 'has-error': errors.step_locked_bg }" />
                                                    </Field>
                                                    <ErrorMessage name="step_locked_bg" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Lock Color</label>
                                                    <Field autocomplete="off" name="step_locked_text_color" v-model="form.step_locked_text_color" type="text" label="element color">
                                                        <color-picker v-model="form.step_locked_text_color" :classes="{ 'has-error': errors.step_locked_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="step_locked_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('hdrsection')" ref="hdrsetting" >
                                <div class="section_header">
                                    <h2>Header</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Company Name</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                                        <Field autocomplete="off" type="text" name="company_branding" v-model="form.company_branding" placeholder="ex: Superfit Coaching" />
                                                    </div>
                                                    <ErrorMessage name="company_branding" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Header Background Color</label>
                                                    <Field autocomplete="off" name="header_bgcolor" v-model="form.header_bgcolor" type="text" label="element color">
                                                        <color-picker v-model="form.header_bgcolor" :classes="{ 'has-error': errors.header_bgcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="header_bgcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Header Text Color</label>
                                                    <Field autocomplete="off" name="header_textcolor" v-model="form.header_textcolor" type="text" label="element color">
                                                        <color-picker v-model="form.header_textcolor" :classes="{ 'has-error': errors.header_textcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="header_textcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <ul class="tab_sec mt-2">
                                                <li @click="headerTab = 'logo'" :class="{ active: headerTab === 'logo' }">Header Logo</li>
                                                <li @click="headerTab = 'author'" :class="{ active: headerTab === 'author' }">Header Author Image</li>
                                            </ul>
                                            <div class="pt-2" v-if="headerTab === 'logo'">
                                                <image-library v-model="form.company_logo" image-type="public-logo" upload-text="Logo" />
                                            </div>
                                            <div class="pt-2" v-if="headerTab === 'author'">
                                                <image-library v-model="form.author" image-type="avatar" upload-text="Logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('cvrsection')" ref="cvrsetting">
                                <div class="section_header">
                                    <h2>Cover</h2>
                                    <span class="status" :style="`color: ${form.display_cover ? '#2f7eed' : '#999'};`">{{ form.display_cover ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show <label>Upload a small screen cover for a better view</label></div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="cover" class="switch_option capsule_btn border-0 p-0">
                                                <input type="checkbox" id="cover" :true-value="1" :false-value="0" v-model="form.display_cover" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="content_details">
                                                <div class="cover_type">
                                                    <ul class="type_list">
                                                        <li>
                                                            <label for="clr_text">
                                                                <input type="radio" id="clr_text" :value="1" v-model="form.cover_type" hidden>
                                                                <img src="@/assets/images/background.svg" alt="">
                                                                <h5>Color + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_text">
                                                                <input type="radio" id="img_text" :value="2" v-model="form.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_only">
                                                                <input type="radio" id="img_only" :value="3" v-model="form.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image Only</h5>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="setting_wpr" v-if="form.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Headline <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                                <Field autocomplete="off" type="text" name="headline" v-model="form.headline" placeholder="Compelling Headline Goes Here" />
                                                            </div>
                                                            <ErrorMessage name="headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="mt-4" v-show="headlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color</label>
                                                                <Field autocomplete="off" name="cover_textcolor" v-model="form.cover_textcolor" type="text" label="element color">
                                                                    <color-picker v-model="form.cover_textcolor" :classes="{ 'has-error': errors.cover_textcolor }" />
                                                                </Field>
                                                                <ErrorMessage name="cover_textcolor" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm">
                                                            <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                            <label for="headline_shadow" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div v-if="form.headline_setting.has_shadow">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-x</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="off" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-y</label>
                                                                    <div class="field_wpr ">
                                                                        <Field autocomplete="off" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-3">
                                                                <div class="group_item">
                                                                    <label class="input_label">Blur-Radius</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="off" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Shadow Color</label>
                                                                    <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                                        <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Sub Headline <button type="button" class="px-1" @click="subHeadlineSetting = !subHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.sub_headline }">
                                                                <Field autocomplete="off" type="text" name="sub_headline" v-model="form.sub_headline" placeholder="Enter text here" />
                                                            </div>
                                                            <ErrorMessage name="sub_headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="mt-4 mb-4" v-show="subHeadlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color</label>
                                                                <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                                </Field>
                                                                <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm">
                                                                <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                                    <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        <div v-if="form.subheadline_setting.has_shadow">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-x</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="off" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-y</label>
                                                                    <div class="field_wpr ">
                                                                        <Field autocomplete="off" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-3">
                                                                <div class="group_item">
                                                                    <label class="input_label">Blur-Radius</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="off" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Shadow Color</label>
                                                                    <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                                        <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr mt-5" v-if="form.cover_type !== 1">
                                                    <h3 class="sub_header mt-1">Cover Image</h3>
                                                    <image-library v-model="form.ep_logo" image-type="public-cover" upload-text="Cover" />
                                                    <div class="border-bottom">
                                                        <div class="capsule_elm">
                                                                <h5>Small Screen Image?</h5>
                                                                <label for="small_image" class="switch_option capsule_btn py-3">
                                                                    <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.has_small_screen_cover" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        <image-library v-if="form.has_small_screen_cover" v-model="form.ep_logo_small" image-type="public-cover-small" upload-text="Cover" />
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Add Image Overlay?</h5>
                                                        <label for="overlay" class="switch_option capsule_btn py-3">
                                                            <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.has_cover_overlay" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div v-if="form.has_cover_overlay" class="setting_wpr">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay Color</label>
                                                                <Field autocomplete="off" name="overlay_color" v-model="form.overlay_color" type="text" label="element color">
                                                                    <color-picker v-model="form.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                                </Field>
                                                                <ErrorMessage name="overlay_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay opacity</label>
                                                                <div class="field_wpr" :class="{ 'has-error': errors.overlay_opacity }">
                                                                    <Field autocomplete="off" type="number" name="overlay_opacity" v-model="form.overlay_opacity" min="0" max="100" placeholder="68" />
                                                                </div>
                                                                <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.cover_type === 1">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Cover Background</label>
                                                            <Field autocomplete="off" name="cover_background_color" v-model="form.cover_background_color" type="text" label="element color">
                                                                <color-picker v-model="form.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                                            </Field>
                                                            <ErrorMessage name="cover_background_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Color</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Step Button Color</label>
                                                    <Field autocomplete="off" name="step_complete_button_bg_color" v-model="form.step_complete_button.bg_color" type="text" label="element color">
                                                        <color-picker v-model="form.step_complete_button.bg_color" :classes="{ 'has-error': errors.step_complete_button_bg_color }" />
                                                    </Field>
                                                    <ErrorMessage name="step_complete_button_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Step Button Text Color</label>
                                                    <Field autocomplete="off" name="step_complete_button_text_color" v-model="form.step_complete_button.text_color" type="text" label="element color">
                                                        <color-picker v-model="form.step_complete_button.text_color" :classes="{ 'has-error': errors.step_complete_button_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="step_complete_button_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Tab Button Color</label>
                                                    <Field autocomplete="off" name="tab_complete_button_bg_color" v-model="form.tab_complete_button.bg_color" type="text" label="element color">
                                                        <color-picker v-model="form.tab_complete_button.bg_color" :classes="{ 'has-error': errors.tab_complete_button_bg_color }" />
                                                    </Field>
                                                    <ErrorMessage name="tab_complete_button_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Tab Button Text Color</label>
                                                    <Field autocomplete="off" name="tab_complete_button_text_color" v-model="form.tab_complete_button.text_color" type="text" label="element color">
                                                        <color-picker v-model="form.tab_complete_button.text_color" :classes="{ 'has-error': errors.tab_complete_button_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="tab_complete_button_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="ftrsetting" @click="goTo('ftrsection')">
                                <div class="section_header">
                                    <h2>Footer</h2>
                                    <span class="status" :style="`color: ${form.display_footer ? '#2f7eed' : '#999'};`">{{ form.display_footer ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show</div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="has-login-footer" class="switch_option capsule_btn">
                                                <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.display_footer" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <p class="saved-style-text pt-1" @click="handleApplySavedStyle('footer')">Apply saved footer styling</p>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr content_details mt-2">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <div class="capsule_elm">
                                                            <h5>Facebook</h5>
                                                            <label for="facebook" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.has_facebook" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <template v-if="form.has_facebook">
                                                            <div class="field_wpr" :class="{ 'has-error': errors.footer_facebook }">
                                                                <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                            </div>
                                                            <ErrorMessage name="footer_facebook" class="text-danger" />
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <div class="capsule_elm">
                                                            <h5>Twitter</h5>
                                                            <label for="twitter" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.has_twitter" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <template v-if="form.has_twitter">
                                                            <div class="field_wpr" :class="{ 'has-error': errors.footer_twitter }">
                                                                <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                            </div>
                                                            <ErrorMessage name="footer_twitter" class="text-danger" />
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <div class="capsule_elm">
                                                            <h5>Instagram</h5>
                                                            <label for="instagram" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.has_instagram" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <template v-if="form.has_instagram">
                                                            <div class="field_wpr" :class="{ 'has-error': errors.footer_instagram }">
                                                                <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                            </div>
                                                            <ErrorMessage name="footer_instagram" class="text-danger" />
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <div class="capsule_elm">
                                                            <h5>Linkedin</h5>
                                                            <label for="linkedin" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="linkedin" :true-value="1" :false-value="0" v-model="form.has_linkedin" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <template v-if="form.has_linkedin">
                                                            <div class="field_wpr" :class="{ 'has-error': errors.footer_linkedin }">
                                                                <Field type="text" name="footer_linkedin" v-model="form.footer_linkedin" placeholder="Linkedin link here" rules="url|validate_url:linkedin" label="linkedin" />
                                                            </div>
                                                            <ErrorMessage name="footer_linkedin" class="text-danger" />
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <div class="capsule_elm">
                                                            <h5>Youtube</h5>
                                                            <label for="youtube" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="youtube" :true-value="1" :false-value="0" v-model="form.has_youtube" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <template v-if="form.has_youtube">
                                                            <div class="field_wpr" :class="{ 'has-error': errors.footer_youtube }">
                                                                <Field type="text" name="footer_youtube" v-model="form.footer_youtube" placeholder="Youtube link here" rules="url|validate_url:youtu" label="youtube" />
                                                            </div>
                                                            <ErrorMessage name="footer_youtube" class="text-danger" />
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Icons Color</label>
                                                        <Field autocomplete="off" name="footer_textcolor" v-model="form.footer_textcolor" type="text" label="element color">
                                                            <color-picker v-model="form.footer_textcolor" :classes="{ 'has-error': errors.footer_textcolor }" />
                                                        </Field>
                                                        <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Background Color</label>
                                                        <Field autocomplete="off" name="footer_bgcolor" v-model="form.footer_bgcolor" type="text" label="element color">
                                                            <color-picker v-model="form.footer_bgcolor" :classes="{ 'has-error': errors.footer_bgcolor }" />
                                                        </Field>
                                                        <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Copyright Text</label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.footer_company }">
                                                            <Field autocomplete="off" type="text" name="footer_company" v-model="form.footer_company" placeholder="© 2022 - Superfit Coaching" />
                                                        </div>
                                                        <ErrorMessage name="footer_company" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Terms Link</label>
                                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.footer_terms }">
                                                            <Field autocomplete="off" type="text" name="footer_terms" v-model="form.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="footer_terms" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Privacy Link</label>
                                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.footer_policy }">
                                                            <Field autocomplete="off" type="text" name="footer_policy" v-model="form.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="footer_policy" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                 <div class="section_header">
                                    <h2>Delivery Message</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                 </div>
                                 <div class="toggle_content">
                                    <div class="color_container mt-1 delivery-message">
                                        <ul class="tab_sec">
                                            <li :class="messageTab === 'invitation' ? 'active' : ''" @click="messageTab = 'invitation'">Invitation Message</li>
                                            <li :class="messageTab === 'resend' ? 'active' : ''" @click="messageTab = 'resend'">Resend Password Message</li>
                                        </ul>
                                        <div v-if="messageTab === 'invitation'">
                                            <div class="section_title m-0">
                                                <div class="mr-auto">{{form.inv_onoff ? 'Sending Method' : 'Notifications?'}}</div>
                                                <label for="invitation_notification" class="switch_option capsule_btn py-3">
                                                    <input type="checkbox" id="invitation_notification" v-model="form.inv_onoff" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="pt-2" v-show="form.inv_onoff">
                                                <div class="section_wpr mb-2">
                                                    <div class="edit_section border">
                                                        <sending-method v-model="form.invitation_notification" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="messageTab === 'resend'" class="mt-5">
                                            <div class="section_wpr mb-2">
                                                <div class="edit_section border">
                                                    <sending-method v-model="form.reset_notification" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="messageTab === 'invitation'">
                                        <div class="pt-2" v-show="form.inv_onoff">
                                            <div v-if="form.invitation_notification === 1 || form.invitation_notification === 3">
                                                <div class="setting_wpr mb-3">
                                                    <email-component v-model="invitationEmailContent" :errors="errors" :has-email-button="true" :handle-default-email="handleDefaultInvitationEmail" preview-module="playbook" ref="email-component" is-full-view />
                                                </div>
                                            </div>
                                            <div v-if="form.invitation_notification === 2 || form.invitation_notification === 3">
                                                <div class="setting_wpr">
                                                    <sms-component v-model="form.invitation_message.sms_message" :default-sms-handler="true" :media="invitationSmsMedia" media-field-name="invitationSmsMedia" :handle-default-sms="handleDefaultInvitationSms" preview-module="playbook" :update-sms-text="updateSmsText()" ref="invitation-sms-component" />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div v-if="messageTab === 'resend'" class="mt-3">
                                        <div v-if="form.reset_notification === 1 || form.reset_notification === 3">
                                            <div class="setting_wpr mb-3">
                                                <email-component v-model="passwordEmailContent" :errors="errors" :has-email-button="true" :handle-default-email="handleDefaultPasswordEmail" preview-module="playbook" ref="email-component" is-full-view />
                                            </div>
                                        </div>
                                        <div v-if="form.reset_notification === 2 || form.reset_notification === 3">
                                            <div class="setting_wpr">
                                                <sms-component v-model="form.password_message.sms_message" :media="passwordSmsMedia" media-field-name="passwordSmsMedia" :default-sms-handler="true" :handle-default-sms="handleDefaultPasswordSms" preview-module="playbook" :update-sms-text="updateSmsText()" ref="password-sms-component" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true" v-if="tab !== 'drip'">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="playbookUpdateLoader" type="button" class="btn cancel_btn" @click="closeModal">Exit</button>
                        <button :disabled="playbookUpdateLoader" class="btn save_btn" id="save-playbook-button">
                            <i class="fa fa-spin fa-spinner" v-if="playbookUpdateLoader"></i> {{ playbookUpdateLoader ? 'Updating' : 'Update' }}
                        </button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-show="tab !== 'delivery'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_playbook" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_playbook" v-model="previewPlaybook" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="dashboard_content">
                            <div class="client_card border-0">
                                <div class="card_header" :style="`background: ${form.header_bgcolor}; color: ${form.header_textcolor};`" ref="hdrsection" @click="setting('hdrsetting')">
                                    <nav>
                                        <img class="logo_img" :src="form.company_logo ? form.company_logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                                        <a>
                                            <h4>Dashboard</h4>
                                        </a>
                                    </nav>
                                    <div class="user_box" @click="accountDrop = !accountDrop">
                                        <img :src="form.author ? form.author : user.profile_pic" :alt="user.full_name">
                                        <div class="user_info">
                                            <h5 :style="`color: ${form.header_textcolor};`">{{ user.full_name }}</h5>
                                        </div>
                                        <ul class="account_drop" :class="{active : accountDrop}">
                                            <li><i class="fas fa-home"></i> Primary Location</li>
                                            <li><i class="far fa-user"></i> Profile Info</li>
                                            <li><i class="far fa-file"></i> My Files</li>
                                            <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card_body" ref="cvrsection" @click="setting('cvrsetting')" v-if="form.display_cover && form.has_small_screen_cover" :class="{'cover-image-sm' : form.cover_type == 1}">
                                    <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                                        <source v-if="form.ep_logo_small != null && form.ep_logo_small != '' && form.ep_logo_small != 'null'" :srcset="`${form.ep_logo_small}`" media="(max-width: 768px)">
                                        <img :src="form.ep_logo_small ? form.ep_logo_small : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                    </picture>
                                    <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                                    <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                    <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                    <div class="banner_content">
                                        <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size / 3 < 20 ? 20 : form.headline_setting.font_size / 3}px; line-height: ${form.headline_setting.font_size / 3 < 20 ? 26 : (parseInt(form.headline_setting.font_size) + 20) / 3}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.cover_textcolor}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                                        <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size / 3 < 11 ? 11 : form.subheadline_setting.font_size / 3}px; line-height: ${form.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(form.subheadline_setting.font_size) + 20) / 3}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.sub_headline }}</h1>
                                    </div>
                                </div>
                            </div>
                            <ul class="mobile_menu w-100">
                                <li><i class="fas fa-angle-left"></i>Back</li>
                                <li @click="openSlide('offer')" v-if="selectedPlaybook.display_playbook_offer">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.82682 7.14098C4.55389 5.71402 5.71402 4.55388 7.14098 3.82682C8.09748 3.33947 9.09148 3.15918 10.0908 3.07753C11.0377 3.00018 12.1873 3.0002 13.5199 3.00025L25.1389 3.00013C26.1675 2.99917 27.0742 2.99833 27.9552 3.20979C28.7289 3.39556 29.4686 3.70199 30.1471 4.11777C30.9196 4.59109 31.5602 5.23292 32.2869 5.96092L51.8948 25.569C53.3337 27.0079 54.5213 28.1953 55.4096 29.2418C56.3322 30.3285 57.0795 31.4284 57.5052 32.7379C58.1649 34.7688 58.1649 36.9564 57.5052 38.9873C57.0795 40.2967 56.3322 41.3966 55.4096 42.4833C54.5213 43.5298 53.3337 44.7172 51.8948 46.156L46.1561 51.8948C44.7172 53.3337 43.5296 54.5213 42.4833 55.4096C41.3966 56.3322 40.2967 57.0797 38.987 57.5052C36.9564 58.1649 34.7688 58.1649 32.7379 57.5052C31.4285 57.0797 30.3286 56.3322 29.2418 55.4096C28.1953 54.5213 27.008 53.3337 25.569 51.8948L5.96092 32.2869C5.23292 31.5601 4.59107 30.9196 4.11778 30.1473C3.70197 29.4688 3.39557 28.7289 3.20979 27.9551C2.99832 27.0744 2.99917 26.1674 3.00013 25.1389L3.00026 13.5199C3.00021 12.1873 3.00017 11.0377 3.07754 10.0909C3.15919 9.09148 3.33947 8.09748 3.82682 7.14098ZM20.6954 15.6393C17.9032 15.6393 15.6396 17.9029 15.6396 20.6951C15.6396 23.4873 17.9032 25.7508 20.6954 25.7508C23.4876 25.7508 25.7511 23.4873 25.7511 20.6951C25.7511 17.9029 23.4876 15.6393 20.6954 15.6393Z" fill="#999999"/>
                                    </svg>
                                    Offer
                                </li>
                                <li @click="openSlide('instructor')" v-if="selectedPlaybook.display_coach">
                                    <svg width="50" height="54" viewBox="0 0 50 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.0717 43.5574C7.3429 36.5812 15.646 32.0607 25.0001 32.0607C34.3541 32.0607 42.6572 36.5812 47.9283 43.5574C47.9521 43.5889 47.9761 43.6207 48.0001 43.6522C48.4533 44.2517 48.9393 44.8943 49.2866 45.5144C49.7068 46.2649 50.0186 47.1329 49.9991 48.1888C49.9836 49.0373 49.7266 49.8319 49.4143 50.4592C49.102 51.0865 48.6232 51.7705 47.9555 52.2947C47.0648 52.9936 46.0952 53.241 45.2338 53.3441C44.4783 53.4347 43.5827 53.4347 42.6824 53.4344C42.6417 53.4344 42.6011 53.4344 42.5605 53.4344H7.43943C7.39885 53.4344 7.35827 53.4344 7.31771 53.4344C6.41734 53.4347 5.5218 53.4347 4.76613 53.3441C3.9048 53.241 2.9352 52.9936 2.04467 52.2947C1.37687 51.7705 0.898042 51.0865 0.585771 50.4592C0.273501 49.8319 0.0163741 49.0373 0.000851407 48.1888C-0.0184651 47.1329 0.293084 46.2649 0.713453 45.5144C1.06072 44.8943 1.54671 44.2517 1.99991 43.6522C2.02396 43.6207 2.04787 43.5889 2.0717 43.5574Z" fill="#999999"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3047 14.6945C10.3047 6.57893 16.8836 0 24.9992 0C33.1146 0 39.6937 6.57893 39.6937 14.6945C39.6937 22.8101 33.1146 29.3889 24.9992 29.3889C16.8836 29.3889 10.3047 22.8101 10.3047 14.6945Z" fill="#999999"/>
                                    </svg>
                                    Coach
                                </li>
                                <li @click="openSlide('menu')">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M47.8032 2.19668C50.7321 5.12558 50.7321 9.8743 47.8032 12.8032C44.8742 15.7321 40.1257 15.7321 37.1967 12.8032C34.2678 9.8743 34.2678 5.12558 37.1967 2.19668C40.1257 -0.732226 44.8742 -0.732226 47.8032 2.19668Z" fill="#999999"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4997 22.0136C47.4997 20.9916 47.4997 20.4806 47.3337 20.1916C47.1672 19.9012 46.9979 19.7579 46.6839 19.6416C46.3714 19.5258 45.7739 19.6261 44.5792 19.8266C40.728 20.4728 36.6325 19.3103 33.661 16.3387C30.6893 13.3671 29.5268 9.27152 30.173 5.42042C30.3735 4.22555 30.4738 3.62813 30.358 3.31563C30.2418 3.00176 30.0985 2.83249 29.808 2.66586C29.519 2.49994 29.008 2.49994 27.986 2.49994H14.3966C12.3843 2.49991 10.7233 2.49989 9.37038 2.61041C7.96514 2.72524 6.6734 2.97164 5.46006 3.58988C3.57845 4.5486 2.04866 6.07839 1.08994 7.96C0.471698 9.17332 0.225299 10.4651 0.1105 11.8703C-4.93877e-05 13.2233 -2.42479e-05 14.8841 7.51906e-07 16.8965V35.603C-2.42479e-05 37.6154 -4.93877e-05 39.2762 0.1105 40.6292C0.225299 42.0344 0.471698 43.3261 1.08994 44.5396C2.04866 46.4211 3.57845 47.9509 5.46006 48.9096C6.6734 49.5279 7.96514 49.7743 9.37038 49.8891C10.7233 49.9996 12.3842 49.9996 14.3966 49.9996H33.103C35.1155 49.9996 36.7762 49.9996 38.1292 49.8891C39.5345 49.7743 40.8262 49.5279 42.0397 48.9096C43.9212 47.9509 45.4509 46.4211 46.4097 44.5396C47.0279 43.3261 47.2744 42.0344 47.3892 40.6292C47.4997 39.2762 47.4997 37.6154 47.4997 35.603V22.0136ZM9.99993 27.4998C9.99993 26.119 11.1192 24.9998 12.4999 24.9998H32.4998C33.8805 24.9998 34.9997 26.119 34.9997 27.4998C34.9997 28.8805 33.8805 29.9997 32.4998 29.9997H12.4999C11.1192 29.9997 9.99993 28.8805 9.99993 27.4998ZM9.99993 37.4997C9.99993 36.1189 11.1192 34.9997 12.4999 34.9997H27.4998C28.8805 34.9997 29.9998 36.1189 29.9998 37.4997C29.9998 38.8804 28.8805 39.9997 27.4998 39.9997H12.4999C11.1192 39.9997 9.99993 38.8804 9.99993 37.4997Z" fill="#999999"/>
                                    </svg>
                                    Menu
                                </li>
                            </ul>
                            <div class="left_side">
                                <div class="section_item py-4">
                                    <h3 class="m-0">
                                        Playbook Steps {{ form.step_headline }}
                                    </h3>
                                </div>
                                <div class="step_card" v-for="(step, s) of playbookSteps" :key="s">
                                    <template v-if="!step.is_draft">
                                        <div class="thumb" v-if="form.has_step_thumb">
                                            <img v-if="form.has_default_step_thumbnail" :src=" form.default_step_thumbnail ? form.default_step_thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                                            <img v-else :src=" step.thumbnail ? step.thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                                        </div>
                                        <div class="step_info pointer">
                                            <h5>{{ step.title && step.title.length > 20 ? step.title.substr(0, 20) + '...' : step.title }}</h5>
                                            <p>{{ step.thumb_desc && step.thumb_desc.length > 40 ? step.thumb_desc.substr(0, 40) + '...' : step.thumb_desc }}</p>
                                        </div>
                                        <div class="step_lock" :style="`background: ${form.step_locked_bg}; color: ${form.step_locked_text_color};`" v-if="form.must_be_completed && s > unlockedStep"><i class="fas fa-lock" :style="`border-color: ${form.step_locked_text_color};`"></i></div>
                                    </template>
                                </div>
                                <div class="section_item pt-5 pb-0" v-if="selectedPlaybook.show_faq">
                                    <h3>Playbook FAQ's</h3>
                                    <ul class="faq_list">
                                        <li @click="faqAcc($event)" v-for="(faq, f) of playbookFaqs" :key="f" class="small-preview">
                                            <h5 class="faq-acc-header" :style="`background: ${selectedPlaybook.faq_settings.btn_color}; color: ${selectedPlaybook.faq_settings.btn_text_color};`">{{ faq.question }} <i class="fas fa-angle-down"></i></h5>
                                            <p v-html="faq.answer"></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="right_side">
                                <div class="section_item coach_card" v-if="selectedPlaybook.display_coach">
                                    <h3>{{ selectedPlaybook.coach_headline ? selectedPlaybook.coach_headline : 'Coach Headline' }}</h3>
                                    <div class="user_box">
                                        <img :src="selectedPlaybook.coach_image ? selectedPlaybook.coach_image : require('@/assets/images/customer.svg')" alt="">
                                        <div class="user_info">
                                            <h3>{{ selectedPlaybook.coach_name ? selectedPlaybook.coach_name : 'Joe Trainer' }}</h3>
                                            <h5>{{ selectedPlaybook.coach_title ? selectedPlaybook.coach_title : 'PHD, CSCS' }}</h5>
                                        </div>
                                    </div>
                                    <p v-html="form.coach_bio"></p>
                                </div>
                                <div class="section_item action_sec" v-if="selectedPlaybook.recipient_ask.display == 1 || selectedPlaybook.recipient_complete.display == 1 || selectedPlaybook.recipient_complete.display == 1 || customButtons.filter(b => b.show_button == 1).length">
                                    <h3>Additional Actions</h3>
                                    <ul class="button_list mb-1">
                                        <template v-for="(button, b) in selectedPlaybook.button_ordering" :key="b">
                                            <li v-if="button == 'ask' && selectedPlaybook.recipient_ask.display == 1">
                                                <button type="button" :style="`background-color: ${ selectedPlaybook.recipient_ask.button_color }; color: ${ selectedPlaybook.recipient_ask.button_txt_color };`">
                                                    <i class="fas fa-plus"></i>{{ selectedPlaybook.recipient_ask.button_text }}
                                                </button>
                                            </li>
                                            <li v-else-if="button == 'understand' && selectedPlaybook.recipient_understand.display == 1">
                                                <button type="button" :style="`background-color: ${ selectedPlaybook.recipient_understand.button_color }; color: ${ selectedPlaybook.recipient_understand.button_txt_color };`">
                                                    <i class="fas fa-plus"></i>{{ selectedPlaybook.recipient_understand.button_text }}
                                                </button>
                                            </li>
                                            <li v-else-if="button == 'complete' && selectedPlaybook.recipient_complete.display == 1">
                                                <button type="button" :style="`background-color: ${ selectedPlaybook.recipient_complete.button_color }; color: ${ selectedPlaybook.recipient_complete.button_txt_color };`">
                                                    <i class="fas fa-plus"></i>{{ selectedPlaybook.recipient_complete.button_text }}
                                                </button>
                                            </li>
                                        </template>
                                        <template v-for="(button, b) of customButtons" :key="b">
                                            <li v-if="button.show_button">
                                                <button type="button" :style="`background-color: ${ button.bg_color }; color: ${ button.text_color };`">
                                                    {{ button.name }}
                                                </button>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                            <div class="w-100" ref="ftrsection" @click="setting('ftrsetting')">
                                <footer class="dashboard_footer" v-show="form.display_footer" :style="`background: ${form.footer_bgcolor};`">
                                    <ul>
                                        <li class="pointer" v-if="form.has_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                        <li class="pointer" v-if="form.has_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                        <li class="pointer" v-if="form.has_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                        <li class="pointer" v-if="form.has_linkedin"><a :href="form.footer_linkedin" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                                        <li class="pointer" v-if="form.has_youtube"><a :href="form.footer_youtube" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                                    </ul>
                                    <h4 :style="`color: ${form.footer_textcolor};`">© {{ form.footer_company }}</h4>
                                    <p>
                                        <a href="javascript:void(0);" :style="`color: ${form.footer_textcolor};`">Terms</a>
                                        <a href="javascript:void(0);" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                                        <span v-html="form.white_label"></span>
                                    </p>
                                </footer>
                            </div>
                        </div>
                    </div>
                    <!-- Instructor & Step listing -->
                    <div class="slide_box_container" :style="`pointer-events:${instructorDetails || steplistDetails || offerDetails ? 'all' : 'none'}`">
                        <div class="slide_box" :class="{'active' : offerDetails}">
                            <button class="close_btn" @click="offerDetails = false;"><i class="fas fa-times"></i></button>
                            <div class="box_container">
                                <div class="section_item product_card" v-if="selectedPlaybook.display_playbook_offer">
                                    <h3 v-if="selectedPlaybook.display_offer_headline">{{ selectedPlaybook.offer_headline }}</h3>
                                    <img :src="selectedPlaybook.offer_image ? selectedPlaybook.offer_image : require('@/assets/images/thumb/offer-placeholder.svg')" alt="product-image">
                                </div>
                            </div>
                        </div>
                        <div class="slide_box" :class="{'active' : instructorDetails}">
                            <button class="close_btn" @click="instructorDetails = false;"><i class="fas fa-times"></i></button>
                            <div class="box_container">
                                <div class="instructor">
                                    <h3>{{ selectedPlaybook.coach_headline ? selectedPlaybook.coach_headline : 'Coach Headline' }}</h3>
                                    <div class="user_box">
                                        <img :src="selectedPlaybook.coach_image ? selectedPlaybook.coach_image : require('@/assets/images/customer.svg')" alt="">
                                        <div class="user_info">
                                            <h3>{{ selectedPlaybook.coach_name ? selectedPlaybook.coach_name : 'Joe Trainer' }}</h3>
                                            <h5>{{ selectedPlaybook.coach_title ? selectedPlaybook.coach_title : 'PHD, CSCS' }}</h5>
                                        </div>
                                    </div>
                                    <p v-html="form.coach_bio"></p>
                                </div>
                            </div>
                        </div>
                        <div class="slide_box" :class="{'active' : steplistDetails}">
                            <button class="close_btn" @click="steplistDetails = false;"><i class="fas fa-times"></i></button>
                            <div class="box_container">
                                <div class="step_listing border-0 p-0">
                                    <div class="progress_sec" v-if="form.display_progressbar">
                                        <h3>Steps Completed</h3>
                                        <div class="status mb-1 mt-2"><span :style="`background-color: ${selectedPlaybook.progressbar_color ? selectedPlaybook.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                                    </div>
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`" class="mb-4">Course Content</h3>
                                    <ul>
                                        <li class="step_card" v-for="(step, s) in playbookSteps" :key="s">
                                            <template v-if="!step.is_draft">
                                                <div class="items">
                                                    <div class="thumb" v-if="selectedPlaybook.has_step_thumb">
                                                        <img v-if="form.has_default_step_thumbnail" :src="form.default_step_thumbnail ? form.default_step_thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                                                        <img v-else :src="step.thumbnail ? step.thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                                                    </div>
                                                    <div class="step_info">
                                                        <h5>{{ step.title }}</h5>
                                                        <p>{{ step.thumb_desc && step.thumb_desc.length > 60 ? step.thumb_desc.substr(0, 60) + '...' : step.thumb_desc }}</p>
                                                    </div>
                                                </div>
                                                <div class="step_lock" :style="`background: ${selectedPlaybook.step_locked_bg}; color: ${selectedPlaybook.step_locked_text_color};`" v-if="selectedPlaybook.must_be_completed && s > unlockedStep"><i class="fas fa-lock" :style="`border-color: ${selectedPlaybook.step_locked_text_color};`"></i></div>
                                            </template>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-show="tab === 'delivery'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_msg" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_msg" v-model="previewMsg" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="notifPreview = 'email'" :class="{'active': notifPreview === 'email'}" v-if="(messageTab === 'invitation' && form.invitation_notification === 3) || (messageTab === 'resend' && form.reset_notification === 3)">E-mail</li>
                    <li @click="notifPreview = 'sms'" :class="{'active': notifPreview === 'sms'}" v-if="(messageTab === 'invitation' && form.invitation_notification === 3) || (messageTab === 'resend' && form.reset_notification === 3)">SMS</li>
                    <li @click="notifPreview = 'email'" class="active" v-if="(messageTab === 'invitation' && form.invitation_notification === 1) || (messageTab === 'resend' && form.reset_notification === 1)">E-mail</li>
                    <li @click="notifPreview = 'sms'" class="active" v-if="(messageTab === 'invitation' && form.invitation_notification === 2) || (messageTab === 'resend' && form.reset_notification === 2)">SMS</li>
                </ul>
                <div class="cell" v-if="messageTab === 'invitation'">
                    <div class="content_area" v-if="(form.invitation_notification === 3 && notifPreview === 'email') || form.invitation_notification === 1">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(invitationEmailContent.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="invitationEmailContent.show_email_logo">
                                <img :src="invitationEmailContent.email_logo ? invitationEmailContent.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(invitationEmailContent.email, invitationEmailContent.email_button_bg_color, invitationEmailContent.email_button_text_color)"></div>
                            <div v-html="generateSignature(invitationEmailContent.is_signature, invitationEmailContent.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="(form.invitation_notification === 3 && notifPreview === 'sms') || form.invitation_notification === 2">
                        <div class="sms_preview">
                            <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(invitationSmsText)"></div>
                        </div>
                    </div>
                </div>
                <div class="cell" v-if="messageTab === 'resend'">
                    <div class="content_area" v-if="(form.reset_notification === 3 && notifPreview === 'email') || form.reset_notification === 1">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(passwordEmailContent.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="passwordEmailContent.show_email_logo">
                                <img :src="passwordEmailContent.email_logo ? passwordEmailContent.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(passwordEmailContent.email, passwordEmailContent.email_button_bg_color, passwordEmailContent.email_button_text_color)"></div>
                            <div v-html="generateSignature(passwordEmailContent.is_signature, passwordEmailContent.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="(form.reset_notification === 3 && notifPreview === 'sms') || form.reset_notification === 2">
                        <div class="sms_preview">
                            <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(passwordSmsText)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <preview-message v-model="previewMsg"/>
        <preview-playbook v-model="previewPlaybook"/>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))
    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
    const SmsComponent = defineAsyncComponent(() => import('@/components/SmsComponent'))
    const EmailComponent = defineAsyncComponent(() => import('@/components/EmailComponent'))
    const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))
    const PreviewMessage = defineAsyncComponent(() => import('@/pages/playbook/components/PreviewMessage'))
    const PreviewPlaybook = defineAsyncComponent(() => import('@/pages/playbook/components/PreviewPlaybook'))

    import Toastr from '@/utils/Toastr'
    import Multiselect from '@vueform/multiselect'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Playbook Setting',

        data () {
            return {
                tab: 'global',
                createCategory: false,
                headlineSetting: false,
                subHeadlineSetting: false,
                preview: true,
                previewPlaybook: false,
                deliveryTab: 'email',
                previewMsg: false,
                whiteLabel: {},
                accountDrop: false,
                notifPreview: 'email',
                headerTab: 'logo',
                toggleAll: 1,
                form: {
                    portal_url: '',
                    title: '',
                    description: '',
                    categories: [],
                    must_be_completed: 0,
                    step_locked_bg: '',
                    step_locked_text_color: '#FFF',
                    company_branding: '',
                    header_bgcolor: '',
                    header_textcolor: '',
                    company_logo: '',
                    author: '',
                    display_cover: 0,
                    cover_type: 2,
                    headline: '',
                    cover_textcolor: '',
                    sub_headline: '',
                    cover_subheadline_textcolor: '',
                    headline_setting: {
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    },
                    ep_logo: '',
                    has_small_screen_cover: 0,
                    ep_logo_small: '',
                    has_cover_overlay: 0,
                    overlay_color: '#000',
                    overlay_opacity: 5,
                    cover_background_color: '#2F7FED',
                    has_facebook: 0,
                    has_twitter: 0,
                    has_instagram: 0,
                    has_linkedin: 0,
                    display_footer: 0,
                    footer_facebook: '',
                    footer_twitter: '',
                    footer_instagram: '',
                    footer_linkedin: '',
                    footer_company: '',
                    footer_terms: '',
                    footer_policy: '',
                    footer_textcolor: '#2F7EED',
                    step_complete_button: { bg_color: '#2F7FED', text_color: '#FFF' },
                    tab_complete_button: { bg_color: '#2F7FED', text_color: '#FFF' },
                    has_step_thumb: 1,
                    has_default_step_thumbnail: 0,
                    apply_saved_styling: 0,
                    default_step_thumbnail: '',
                    has_youtube: 0,
                    footer_youtube: '',
                },
                playbookSteps: [],
                playbookFaqs: [],
                categoryForm: {
                    name: '',
                },
                messageTab: 'invitation',
                invitationEmailContent: {},
                passwordEmailContent: {},
                unlockedStep: 0,
                passwordSmsText: '',
                invitationSmsText: '',
                invitationSmsMedia: [],
                passwordSmsMedia: [],
                offerDetails: false,
                instructorDetails: false,
                steplistDetails: false,
                isMounted: false,
            };
        },

        props: {
            modelValue: Boolean,
            targetSection: String,
        },

        emits: ['update:modelValue'],

        components: {
            Multiselect,
            ColorPicker,
            ImageLibrary,
            Field,
            Form,
            ErrorMessage,
            SmsComponent,
            EmailComponent,
            SendingMethod,
            PreviewMessage,
            PreviewPlaybook,
        },

        watch: {
            modelValue (val) {
                const vm = this;
                vm.isMounted = false;

                if (val) {
                    setTimeout(() => {
                        vm.isMounted = true;
                    }, 2000)
                }
            },

            selectedPlaybook () {
                const vm = this;

                vm.resetForm();
            },

            selectedPlaybookSteps (steps) {
                const vm = this;

                vm.playbookSteps  = JSON.parse(JSON.stringify(steps));
            },

            targetSection (val) {
                const vm = this;

                vm.tab = val;
            },

            previewPlaybook (val) {
                if (val) {
                    const button = document.getElementById('save-playbook-button');

                    if (button) {
                        button.click();
                    }
                }
            },

            messageTab (tab) {
                const vm = this;

                if (tab == 'invitation') {
                    vm.invitationSmsText = vm.form.invitation_message && vm.form.invitation_message.media ? vm.form.invitation_message.sms_message + '\n\n' + vm.form.invitation_message.media : vm.form.invitation_message.sms_message;
                } else if (tab == 'resend') {
                    vm.passwordSmsText = vm.form.password_message && vm.form.password_message.media ? vm.form.password_message.sms_message + '\n\n' + vm.form.password_message.media : vm.form.password_message.sms_message;
                }
            }
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                selectedPlaybook: state => state.playbookModule.selectedPlaybook,
                selectedPlaybookSteps: state => state.playbookModule.playbookSteps,
                playbookCategories: state => state.clientPortalModule.playbookCategories,
                customButtons: state => state.playbookModule.customButtons,
                playbookUpdateLoader: state => state.playbookModule.playbookUpdateLoader,
                categorySaveLoader: state => state.playbookModule.categorySaveLoader,
                fontFamily: state => state.commonModule.fontFamily,
                fontWeight: state => state.commonModule.fontWeight,
                playbookDefaultContent: state => state.commonModule.playbookDefaultContent,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();

            if (vm.playbookCategories.length == 0) {
                vm.getPlaybookCategories();
            }

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted () {
            const vm = this;
            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                getPlaybookCategories: 'clientPortalModule/getPlaybookCategories',
                updatePlaybookSetting: 'playbookModule/updatePlaybookSetting',
                createPlaybookCategory: 'playbookModule/createPlaybookCategory',
            }),

            faqAcc (e) {
                let el = e.currentTarget;
                let allEl = document.querySelectorAll('.faq_list li');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    for (let i = 0; i < allEl.length; i++) {
                        allEl[i].classList.remove('show');
                    }

                    el.classList.add('show');
                }
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                })
            },

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            tabSwitch (tab) {
                const vm = this;

                vm.tab = tab;
            },

            resetForm () {
                const vm = this;

                let headline_setting = vm.selectedPlaybook.headline_setting ? JSON.parse(JSON.stringify(vm.selectedPlaybook.headline_setting)) : {};
                let subheadline_setting = vm.selectedPlaybook.subheadline_setting ? JSON.parse(JSON.stringify(vm.selectedPlaybook.subheadline_setting)) : {};

                if (!headline_setting.font_size) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    };
                }

                if (!subheadline_setting.font_size) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    };
                }

                const applySavedStyling = vm.form.apply_saved_styling ? vm.form.apply_saved_styling : 0;

                vm.form = {
                    headline_setting,
                    subheadline_setting,
                    playbook_id: vm.selectedPlaybook.id,
                    portal_url: vm.selectedPlaybook.portal_url ? vm.selectedPlaybook.portal_url : '',
                    title: vm.selectedPlaybook.title ? vm.selectedPlaybook.title : '',
                    description: vm.selectedPlaybook.description ? vm.selectedPlaybook.description : '',
                    categories: vm.selectedPlaybook.categories && vm.selectedPlaybook.categories.length ? vm.selectedPlaybook.categories.map(category => category.category_id) : [],
                    must_be_completed: vm.selectedPlaybook.must_be_completed ? vm.selectedPlaybook.must_be_completed : 0,
                    step_locked_bg: vm.selectedPlaybook.step_locked_bg ? vm.selectedPlaybook.step_locked_bg : '#2F7FED',
                    step_locked_text_color: vm.selectedPlaybook.step_locked_text_color ? vm.selectedPlaybook.step_locked_text_color : '#FFF',
                    company_branding: vm.selectedPlaybook.company_branding ? vm.selectedPlaybook.company_branding : '',
                    header_bgcolor: vm.selectedPlaybook.header_bgcolor ? vm.selectedPlaybook.header_bgcolor : '',
                    header_textcolor: vm.selectedPlaybook.header_textcolor ? vm.selectedPlaybook.header_textcolor : '',
                    company_logo: vm.selectedPlaybook.company_logo ? vm.selectedPlaybook.company_logo : '',
                    author: vm.selectedPlaybook.author ? vm.selectedPlaybook.author : '',
                    display_cover: vm.selectedPlaybook.display_cover ? vm.selectedPlaybook.display_cover : 0,
                    display_progressbar: vm.selectedPlaybook.display_progressbar ? vm.selectedPlaybook.display_progressbar : 0,
                    cover_type: vm.selectedPlaybook.cover_type ? vm.selectedPlaybook.cover_type : 2,
                    headline: vm.selectedPlaybook.headline ? vm.selectedPlaybook.headline : '',
                    cover_textcolor: vm.selectedPlaybook.cover_textcolor ? vm.selectedPlaybook.cover_textcolor : '',
                    sub_headline: vm.selectedPlaybook.sub_headline ? vm.selectedPlaybook.sub_headline : '',
                    cover_subheadline_textcolor: vm.selectedPlaybook.cover_subheadline_textcolor ? vm.selectedPlaybook.cover_subheadline_textcolor : '',
                    ep_logo: vm.selectedPlaybook.ep_logo ? vm.selectedPlaybook.ep_logo : '',
                    has_small_screen_cover: vm.selectedPlaybook.has_small_screen_cover ? vm.selectedPlaybook.has_small_screen_cover : 0,
                    ep_logo_small: vm.selectedPlaybook.ep_logo_small ? vm.selectedPlaybook.ep_logo_small : '',
                    has_cover_overlay: vm.selectedPlaybook.has_cover_overlay ? vm.selectedPlaybook.has_cover_overlay : 0,
                    overlay_color: vm.selectedPlaybook.overlay_color ? vm.selectedPlaybook.overlay_color : '#000',
                    overlay_opacity: vm.selectedPlaybook.overlay_opacity ? vm.selectedPlaybook.overlay_opacity : 5,
                    cover_background_color: vm.selectedPlaybook.cover_background_color ? vm.selectedPlaybook.cover_background_color : '#2F7FED',
                    has_facebook: vm.selectedPlaybook.has_facebook ? vm.selectedPlaybook.has_facebook : 0,
                    has_twitter: vm.selectedPlaybook.has_twitter ? vm.selectedPlaybook.has_twitter : 0,
                    has_instagram: vm.selectedPlaybook.has_instagram ? vm.selectedPlaybook.has_instagram : 0,
                    has_linkedin: vm.selectedPlaybook.has_linkedin ? vm.selectedPlaybook.has_linkedin : 0,
                    display_footer: vm.selectedPlaybook.display_footer ? vm.selectedPlaybook.display_footer : 0,
                    footer_facebook: vm.selectedPlaybook.footer_facebook ? vm.selectedPlaybook.footer_facebook : '',
                    footer_twitter: vm.selectedPlaybook.footer_twitter ? vm.selectedPlaybook.footer_twitter : '',
                    footer_instagram: vm.selectedPlaybook.footer_instagram ? vm.selectedPlaybook.footer_instagram : '',
                    footer_linkedin: vm.selectedPlaybook.footer_linkedin ? vm.selectedPlaybook.footer_linkedin : '',
                    footer_company: vm.selectedPlaybook.footer_company ? vm.selectedPlaybook.footer_company : '',
                    footer_terms: vm.selectedPlaybook.footer_terms ? vm.selectedPlaybook.footer_terms : '',
                    footer_policy: vm.selectedPlaybook.footer_policy ? vm.selectedPlaybook.footer_policy : '',
                    footer_textcolor: vm.selectedPlaybook.footer_textcolor ? vm.selectedPlaybook.footer_textcolor : '#2F7FED',
                    footer_bgcolor: vm.selectedPlaybook.footer_bgcolor ? vm.selectedPlaybook.footer_bgcolor : '#f5f5f5',
                    step_complete_button: vm.selectedPlaybook.step_complete_button ? JSON.parse(JSON.stringify(vm.selectedPlaybook.step_complete_button)) : { bg_color: '#2F7FED', text_color: '#FFF' },
                    tab_complete_button: vm.selectedPlaybook.tab_complete_button ? JSON.parse(JSON.stringify(vm.selectedPlaybook.tab_complete_button)) : { bg_color: '#2F7FED', text_color: '#FFF' },
                    inv_onoff: vm.selectedPlaybook.inv_onoff ? vm.selectedPlaybook.inv_onoff : 0,
                    invitation_notification: vm.selectedPlaybook.invitation_notification ? vm.selectedPlaybook.invitation_notification : 1,
                    reset_notification: vm.selectedPlaybook.reset_notification ? vm.selectedPlaybook.reset_notification : 1,
                    invitation_message: vm.selectedPlaybook && vm.selectedPlaybook.invitation_message ? JSON.parse(JSON.stringify(vm.selectedPlaybook.invitation_message)) : {},
                    password_message: vm.selectedPlaybook && vm.selectedPlaybook.password_message ? JSON.parse(JSON.stringify(vm.selectedPlaybook.password_message)) : {},
                    invitation_show_email_logo: vm.selectedPlaybook.invitation_show_email_logo ? vm.selectedPlaybook.invitation_show_email_logo : 0,
                    invitation_email_logo: vm.selectedPlaybook.invitation_email_logo ? vm.selectedPlaybook.invitation_email_logo : '',
                    invitation_show_email_signature: vm.selectedPlaybook.invitation_show_email_signature ? vm.selectedPlaybook.invitation_show_email_signature : 0,
                    invitation_email_signature: vm.selectedPlaybook.invitation_email_signature ? vm.selectedPlaybook.invitation_email_signature : 0,
                    reset_show_email_logo: vm.selectedPlaybook.reset_show_email_logo ? vm.selectedPlaybook.reset_show_email_logo : 0,
                    reset_email_logo: vm.selectedPlaybook.reset_email_logo ? vm.selectedPlaybook.reset_email_logo : '',
                    reset_show_email_signature: vm.selectedPlaybook.reset_show_email_signature ? vm.selectedPlaybook.reset_show_email_signature : 0,
                    reset_email_signature: vm.selectedPlaybook.reset_email_signature ? vm.selectedPlaybook.reset_email_signature : 0,
                    coach_bio: vm.selectedPlaybook.coach_bio ? vm.selectedPlaybook.coach_bio : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500',
                    has_step_thumb: vm.selectedPlaybook.has_step_thumb ? vm.selectedPlaybook.has_step_thumb : 0,
                    has_default_step_thumbnail: vm.selectedPlaybook.has_default_step_thumbnail ? vm.selectedPlaybook.has_default_step_thumbnail : 0,
                    default_step_thumbnail: vm.selectedPlaybook.default_step_thumbnail ? vm.selectedPlaybook.default_step_thumbnail : '',
                    has_youtube: vm.selectedPlaybook.has_youtube ? vm.selectedPlaybook.has_youtube : 0,
                    footer_youtube: vm.selectedPlaybook.footer_youtube ? vm.selectedPlaybook.footer_youtube : '',
                    apply_saved_styling: applySavedStyling,
                };

                vm.playbookSteps  = JSON.parse(JSON.stringify(vm.selectedPlaybookSteps));
                vm.playbookFaqs   = vm.selectedPlaybook.faqs ? JSON.parse(JSON.stringify(vm.selectedPlaybook.faqs)) : [];

                vm.invitationEmailContent = {
                    defaultHandler: true,
                    subject: vm.form.invitation_message.email_subject,
                    email: vm.form.invitation_message.email_message,
                    email_button_bg_color: vm.form.invitation_message.email_button_bg_color ? vm.form.invitation_message.email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.invitation_message.email_button_text_color ? vm.form.invitation_message.email_button_text_color : '#FFF',
                    show_email_logo: vm.form.invitation_show_email_logo,
                    email_logo: vm.form.invitation_email_logo,
                    is_signature: vm.form.invitation_show_email_signature,
                    signature_id: vm.form.invitation_email_signature,
                    module_id: vm.form.playbook_id,
                };

                vm.passwordEmailContent = {
                    defaultHandler: true,
                    subject: vm.form.password_message.email_subject,
                    email: vm.form.password_message.email_message,
                    email_button_bg_color: vm.form.password_message.email_button_bg_color ? vm.form.password_message.email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.password_message.email_button_text_color ? vm.form.password_message.email_button_text_color : '#FFF',
                    show_email_logo: vm.form.reset_show_email_logo,
                    email_logo: vm.form.reset_email_logo,
                    is_signature: vm.form.reset_show_email_signature,
                    signature_id: vm.form.reset_email_signature,
                    module_id: vm.form.playbook_id,
                };

                vm.invitationSmsText  = vm.form.invitation_message && vm.form.invitation_message.sms_message ? vm.form.invitation_message.sms_message : '';
                vm.passwordSmsText    = vm.form.password_message && vm.form.password_message.sms_message ? vm.form.password_message.sms_message : '';

                vm.invitationSmsMedia = vm.selectedPlaybook.invitation_message && vm.selectedPlaybook.invitation_message.media ? vm.selectedPlaybook.invitation_message.media : [];
                vm.passwordSmsMedia   = vm.selectedPlaybook.password_message && vm.selectedPlaybook.password_message.media ? vm.selectedPlaybook.password_message.media : [];
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError  = setFieldError;
                params.invitation_message.email_subject           = vm.invitationEmailContent.subject;
                params.invitation_message.email_message           = vm.invitationEmailContent.email;
                params.invitation_message.email_button_bg_color   = vm.invitationEmailContent.email_button_bg_color;
                params.invitation_message.email_button_text_color = vm.invitationEmailContent.email_button_text_color;
                params.invitation_show_email_logo                 = vm.invitationEmailContent.show_email_logo;
                params.invitation_email_logo                      = vm.invitationEmailContent.email_logo;
                params.invitation_show_email_signature            = vm.invitationEmailContent.is_signature;
                params.invitation_email_signature                 = vm.invitationEmailContent.signature_id;
                params.invitation_message.media                   = vm.invitationSmsMedia;
                params.password_message.email_subject             = vm.passwordEmailContent.subject;
                params.password_message.email_message             = vm.passwordEmailContent.email;
                params.password_message.email_button_bg_color     = vm.passwordEmailContent.email_button_bg_color;
                params.password_message.email_button_text_color   = vm.passwordEmailContent.email_button_text_color;
                params.password_message.media                     = vm.passwordSmsMedia;
                params.reset_show_email_logo                      = vm.passwordEmailContent.show_email_logo;
                params.reset_email_logo                           = vm.passwordEmailContent.email_logo;
                params.reset_show_email_signature                 = vm.passwordEmailContent.is_signature;
                params.reset_email_signature                      = vm.passwordEmailContent.signature_id;

                vm.updatePlaybookSetting(params);
            },

            handleCreateCategory () {
                const vm = this;

                const categoryForm = vm.$refs['category-form'];

                categoryForm.validate().then((result) => {
                    if (result.valid) {
                        vm.categoryForm.setFieldError = categoryForm.setFieldError;

                        vm.createPlaybookCategory(vm.categoryForm).then((result) => {
                            if (result) {
                                vm.createCategory    = false;
                                vm.categoryForm.name = '';
                                vm.getPlaybookCategories();
                            }
                        });
                    }
                });
            },

            handleDefaultInvitationEmail () {
                const vm = this;
                let email = '<p>A new resource has just been added to your {{company_name}} member area.</p>'
                    email += '<p></p>';
                    email += '<p>Resource: {{playbook_name}} playbook.</p>';
                    email += '<p></p>';
                    email += '<p>{{auto_login_link}}</p>';
                    email += '<p></p>';
                    email += '<p>Cheers!</p>';
                    email += '<p>Team {{company_name}}</p>';

                vm.invitationEmailContent.subject = '[ACCESS] {{playbook_name}}!';
                vm.invitationEmailContent.email   = email;
            },

            handleDefaultInvitationSms () {
                const vm = this;

                vm.form.invitation_message.sms_message  = 'Hi {{first_name}}, \r\n You have been assigned the {{palybook_name}} playbook from {{company_name}}. Click here to access it. \r\n  {{auto_login_sms_link}}';
            },

            handleDefaultPasswordEmail () {
                const vm = this;
                let email = '<p>Hi {{first_name}}!</p>';
                    email += '<p>Please access your client dashboard by clicking this button: </p>';
                    email += '<p></p>';
                    email += '<p>{{auto_login_link}}</p>';
                    email += '<p></p>';
                    email += '<p>Here are your login details for your dashboard. Save this message for future use.</p>';
                    email += '<p>Username:  {{user_name}} </p>';
                    email += '<p>Password: {{password}} </p>';
                    email += '<p>Thanks for being awesome {{first_name}}!</p>';
                    email += '<p>{{ company_owner }}</p>';
                    email += '<p>{{company_name}}</p>';
                    email += '<p></p>';
                    email += '<p><strong>Agreement to copyright:</strong> By accessing this information you agree to all copyrights by {{company_name}} to this intellectual property and agree not to duplicate, redistribute, or alter this information in any manner without written permission.</p>';


                vm.passwordEmailContent.subject = '[ACCESS] Your client dashboard';
                vm.passwordEmailContent.email   = email;
            },

            handleDefaultPasswordSms () {
                const vm = this;

                vm.form.password_message.sms_message  = 'Hi {{first_name}} here is your unique member ID for {{company_name}}: {{member_id}}.\r\nHere\'s your account quick access link for {{playbook_name}} playbook.\r\nAuto-login: {{auto_login_sms_link}}';
            },

            goTo (refName) {
                const vm = this;
                let element = vm.$refs[refName];

                setTimeout(function() {
                    if (element && element.offsetTop) {
                        let top = element.offsetTop;
                        document.querySelector('.preview_content .content_area').scrollTo(0, top);
                    }
                }, 100);
            },

            setting (refName) {
                const vm = this;

                if (refName === 'hdrsetting') {
                    vm.tab = 'header';
                } else if (refName === 'cvrsetting') {
                    vm.tab = 'cover';
                } else if (refName === 'ftrsetting') {
                    vm.tab = 'footer';
                }

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    if (element && element.offsetTop) {
                        let top = element.offsetTop;
                        document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                    }
                }, 10);
            },

            updateSmsText () {
                const vm = this;

                const invitationSms = vm.$refs['invitation-sms-component'];
                const passwordSms   = vm.$refs['password-sms-component'];

                if (invitationSms) {
                    vm.invitationSmsText = invitationSms.smsMedia && invitationSms.smsMedia.media && invitationSms.smsMedia.media[0] ? vm.form.invitation_message.sms_message + '\n\n' + invitationSms.smsMedia.media[0].toString() : vm.form.invitation_message.sms_message;
                }

                if (passwordSms) {
                    vm.passwordSmsText = passwordSms.smsMedia && passwordSms.smsMedia.media && passwordSms.smsMedia.media[0] ? vm.form.password_message.sms_message + '\n\n' + passwordSms.smsMedia.media[0].toString() : vm.form.password_message.sms_message;
                }
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            handleApplySavedStyle (section = 'footer') {
                const vm = this;

                if (vm.playbookDefaultContent) {
                    if (section == 'footer') {
                        vm.form.display_footer   = vm.playbookDefaultContent.display_footer ? 1 : 0;
                        vm.form.footer_company   = vm.playbookDefaultContent.footer_company;
                        vm.form.footer_bgcolor   = vm.playbookDefaultContent.footer_bgcolor;
                        vm.form.footer_policy    = vm.playbookDefaultContent.footer_policy;
                        vm.form.footer_terms     = vm.playbookDefaultContent.footer_terms;
                        vm.form.footer_textcolor = vm.playbookDefaultContent.footer_textcolor;
                        vm.form.footer_facebook  = vm.playbookDefaultContent.footer_facebook;
                        vm.form.footer_twitter   = vm.playbookDefaultContent.footer_twitter;
                        vm.form.footer_instagram = vm.playbookDefaultContent.footer_instagram;
                        vm.form.has_instagram    = vm.playbookDefaultContent.has_footer_instagram ? 1 : 0;
                        vm.form.has_facebook     = vm.playbookDefaultContent.has_footer_facebook ? 1 : 0;
                        vm.form.has_twitter      = vm.playbookDefaultContent.has_footer_twitter ? 1 : 0;
                    }
                }
            },

            openSlide(type){
                const vm = this;
                if(type == 'offer'){
                    vm.steplistDetails = false;
                    vm.instructorDetails = false;
                    vm.offerDetails = true;
                } else if(type == 'instructor'){
                    vm.offerDetails = false;
                    vm.steplistDetails = false;
                    vm.instructorDetails = true;
                } else if(type == 'menu'){
                    vm.instructorDetails = false;
                    vm.offerDetails = false;
                    vm.steplistDetails = true;

                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            toggleDetails (ev) {
                let elm = ev.currentTarget;
                let targetElm = elm.closest('.color_container').querySelector('.content_details');

                if (targetElm.classList.contains('show')) {
                    targetElm.classList.remove('show');
                    elm.innerHTML = 'Open';
                } else {
                    targetElm.classList.add('show');
                    elm.innerHTML = 'Close';
                }
            },

            handleStepThumbUpdate () {

            },

            handleDefaultThumbWarning () {
                const vm = this;

                if (vm.isMounted && vm.form.has_default_step_thumbnail) {
                    const options = Helper.swalWarningOptions('Are you sure?', 'Activating this will disable your ability to update individual thumbnails in your playbook. If you prefer to have individual thumbnails, please ensure this setting is turned off.');

                    Swal.fire(options);
                }
            },

            handleApplySavedStylingWarning () {
                const vm = this;

                if (vm.isMounted && vm.form.apply_saved_styling) {
                    const options = Helper.swalConfirmOptions('Are you sure?', 'Enabling this will update your header, cover, footer, and more with saved styles, and this action cannot be undone.');

                    Swal.fire(options).then((result) => {
                        if (result.isDismissed) {
                            vm.form.apply_saved_styling = 0;
                        }
                    });
                }
            },
        },
    }
</script>

<style scoped>
    .global_setting .section_header {
        margin: 10px 0;
    }
    .global_setting .section_header h2 span{
        font-size: 11px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 500;
        display: block;
        padding-top: 5px;
    }
    .global_setting .color_container{
        border-color: #e9e9e9;
        background: #fff;
        padding: 20px 30px;
    }
    .global_setting .color_container .content_details{
        display: none;
    }
    .global_setting .color_container .content_details.show{
        display: block;
    }
    .section_content .section_header .status{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }
    .global_setting .tabs_content .section_content{
        position: relative;
    }
    .global_setting .tabs_content .section_content:after{
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right: 20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .global_setting .tabs_content .section_content.show:after{
        border: 0;
    }
    .toggle_section{
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .toggle_section i{
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }
    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }
    .global_setting .tabs_content .section_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_content{
        max-height: 10000000px;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content:last-of-type:after{
        border: 0;
    }
    .section_title{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .section_title label{
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #6a6a6a;
        display: block;
        padding: 3px 0;
    }
    .btn_list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }
    .btn_list li{
        background: #fff;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 3px 10px;
        cursor: pointer;
    }
    :deep(.color_container .input_label) {
        color: #121525;
        font-weight: 400;
        margin: 0 0 5px 0;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .cover_type {
        margin: 15px -30px 15px -30px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 30px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }
    .playbook-setting-form{
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    /* Preview area */
    /* .global_setting .preview_content {
        width: 40%;
        width: calc(50% - 350px);
    } */

    .dashboard_content .left_side{
        width: 100%;
        padding: 15px;
        /* margin-top: 15px; */
    }
    .dashboard_content .right_side {
        width: 100%;
        padding: 15px;
        margin-top: 15px;
        margin-bottom: 40px;
        position: relative;
    }
    .dashboard_content .right_side::before {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        border-top: 1px solid #e9e9e9;
    }

    .dashboard_content h3 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .border-item {
        padding: 20px;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        background: #fff;
    }

    .dashboard_content .dotted_item h3 {
        align-items: center;
    }

    .dashboard_content .para {
        font-size: 11px;
        line-height: 15px;
        color: #585858;
        font-weight: 400;
    }

    .step_banner,
    .video_wpr {
        margin-bottom: 5px;
    }

    .step_banner img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 6px;
    }

    .preview_content .status {
        height: 5px;
        border-radius: 3px;
        background: #ddd;
        position: relative;
        max-width: 100%;
    }

    .preview_content .status span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
        background: #2f7fed;
    }

    .step_card {
        display: flex;
        /* border: 1px solid #e9e9e9; */
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .step_card .thumb {
        flex: 0 0 140px;
        position: relative;
    }

    .step_card .thumb>img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px 0 0 5px;
    }

    .step_card .step_info {
        flex: 1;
        padding: 8px 20px;
        background: #fff;
        height: 91px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .step_card .step_info h5 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        max-height: 36px;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .global_setting .step_card .step_info p {
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        max-height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .progress_sec{
        margin-bottom: 10px;
    }
    .product_card {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        overflow: hidden;
        padding: 0;
        line-height: 0;
        background: #fff;
        margin-bottom: 35px;
    }

    .product_card h3 {
        padding: 20px 30px;
        margin: 0;
    }

    .product_card img {
        width: 100%;
        /* height: 130px; */
        object-fit: cover;
    }

    .coach_card {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 10px 15px;
        background: #fff;
        margin-bottom: 35px;
        display: none;
    }

    .coach_card .user_box {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }

    .coach_card .user_box img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }

    .coach_card .user_box .user_info h3 {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }

    .coach_card .user_box .user_info h5 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        margin: 5px 0 0 0;
    }

    .coach_card p {
        font-size: 11px;
        line-height: 15px;
        font-weight: 300;
        color: #717171;
        margin: 15px 0 5px 0;
    }
    :deep(.cell ul.faq_list li p){
        font-size: 11px;
        line-height: 15px;
        padding: 5px 0;
    }
    .dashboard_content .action_sec h3{
        font-weight: 500;
    }

    .button_list li {
        margin-top: 20px;
    }

    .button_list li button {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #121525 !important;
        background: transparent !important;
        padding: 0 !important;
        /* padding: 12px 20px; */
        /* border-radius: 4px; */
        display: flex;
        align-items: center;
        width: 100%;
    }
    .button_list li button i{
        font-size: 9px;
        padding-right: 10px;
    }

    .download_list {
        padding-top: 5px;
    }

    .download_list li {
        border: 1px solid #eaeaea;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 10px;
    }

    .download_list li img {
        width: 40px;
        height: auto;
    }

    .download_list li h5 {
        padding: 0 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .download_list li span {
        margin-left: auto;
        font-size: 12px;
        width: 30px;
        height: auto;
        border-left: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .faq_list {
        list-style-type: none;
    }

    .faq_list li {
        border: 1px solid #eaeaea;
        padding: 15px;
        border-radius: 6px;
        overflow: hidden;
        margin: 15px 0;
        background: #fff;
    }

    .faq_list li h5 {
        font-size: 12px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .cell .faq_list li h5 {
        font-size: 14px;
        line-height: 19px;
    }
    .global_setting .preview_content .faq_list li p{
        padding: 10px 0;
        display: none;
    }
    .global_setting .preview_content .faq_list li :deep(p){
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .faq_list li h5 i {
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .preview_content .faq_list li.show p {
        display: block;
    }

    .faq_list li.show h5 i {
        transform: rotate(-180deg);
    }

    .client_card {
        width: 100%;
        border-radius: 0 0 5px 5px;
    }

    .client_card .card_header {
        padding: 15px 20px;
        border-radius: 0;
    }

    .client_card .card_header h4 {
        font-size: 14px;
        line-height: 20px;
    }

    .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 14px;
    }
    /* .cover-image-sm{
        min-height: 240px;
    } */

    .client_card .card_header .user_info {
        margin-left: 10px;
    }

    .client_card .card_header .user_info span {
        font-size: 10px;
        line-height: 12px;
    }

    .client_card .card_body {
        height: auto;
        text-align: center;
        /* border-radius: 0 0 5px 5px; */
    }
    .client_card .card_body .banner_img{
        position: relative;
    }
    .client_card .card_body .banner_content{
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 2;
    }
    .client_card .card_body h1 {
        font-size: 30px;
        line-height: 45px;
        font-weight: 500;
        color: #fff;
        /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.68); */
        /* text-transform: uppercase; */
        position: relative;
        z-index: 3;
    }

    .client_card .card_body h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: #fff;
        /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        /* letter-spacing: 1px; */
        /* text-transform: uppercase; */
        position: relative;
        z-index: 3;
    }

    .client_card .card_body .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    .preview_content .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }
    .preview_content .dashboard_footer ul li{
        padding: 2px;
    }
    .preview_content .dashboard_footer ul li a {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }
    .preview_content .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }
    .preview_content .dashboard_footer p {
        font-size: 9px;
        line-height: 11px;
    }
    .preview_content .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }

    .global_setting .preview_content p {
        font-size: 9px;
        line-height: 11px;
    }
    .preview_content .cell{
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 30px auto;
    }
    .preview_content .cell:after, .preview_content .cell:before{
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }
    .preview_content .cell:before{
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }
    .preview_content .cell:after{
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }
    .preview_content .cell .content_area{
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #f5f5f5;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .global_setting .msg_preview{
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }
    .global_setting .sms_preview{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }
    .global_setting .msg_preview:after, .global_setting .sms_preview:after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }
    .global_setting .sms_preview:after{
        right: 30px;
        left: auto;
    }
    .global_setting .msg_preview p{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .global_setting .msg_preview p span{
        font-size: 14px;
        line-height: 19px;
        display: block;
        padding-bottom: 3px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msg_preview h2{
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msgFooter p{
        font-size: 11px;
        line-height: 17px;
    }
    .cell .msgFooter p a{
        text-decoration: none;
    }

    .email-preview,
    :deep(.redactor-styles.redactor-in.email-preview p) {
        font-size: 11px !important;
        line-height: 15px !important;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .cell .email-preview{
        overflow: hidden;
    }

    .faq-acc-header {
        padding: 15px;
        margin: -15px -15px 0px -15px;
    }
    .cell .faq-acc-header {
        padding: 15px 20px;
    }

    /* ul.faq_list li:first-child .faq-acc-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    } */

    ul.faq_list li.small-preview {
        padding-bottom: 0;
    }

    /* ul.faq_list li.small-preview .faq-acc-header {
        border-radius: 6px;
    } */
    .preview_content {
        position: relative;
        width: 40%;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }
    .preview_content.show {
        margin-right: 0;
    }
    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }
    .preview_content .close_btn{
        position: absolute;
        left: -35px;
        top: 2px;
    }
    .sender_info{
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }
    .sender_info img{
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }
    .sender_info .info{
        margin: 0;
    }
    .sender_info .info h5{
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }
    .sender_info .info h6{
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }
    .sender_info .info h6 span{
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }
    /* .sender_info .info p{
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #999;
    } */

    :deep(.msg_preview .redactor-styles a){
        padding: 7px 20px !important;
        font-size: 15px !important;
        line-height: 18px !important;
    }

    .step_card {
        position: relative;
        padding: 5px 0 5px 5px;
        background: #fff;
        border-radius: 8px;
    }
    .cell .mobile_menu{
        display: flex;
        padding: 30px 15px 0 15px;
        /* margin-bottom: -15px; */
    }
    .cell .mobile_menu li {
        font-size: 13px;
        line-height: 16px;
    }
    .slide_box_container{
        height: 610px;
        position: absolute;
        top: 60px;
        bottom: 80px;
        left: 15px;
        right: 15px;
        overflow: hidden;
        z-index: 2;
    }
    .slide_box {
        padding: 20px;
        border-radius: 20px 20px 0 0;
        background: #fff;
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: -100%;
        max-height: 430px;
        transition: all .3s ease-in-out;
        box-shadow: 0 -2px 30px rgba(0,0,0,.15);
        text-align: left;
        z-index: 3;
        overflow-y: auto;
    }
    .slide_box::-webkit-scrollbar{
        width: 4px;
    }
    .slide_box::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 2px;
    }
    .slide_box.active{
        bottom: 1px;
    }
    .preview_content .slide_box .close_btn{
        position: sticky;
        float: right;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background: rgba(50,55,59,.6);
        color: #fff;
    }
    :deep(.slide_box .instructor){
        padding-bottom: 15px;
    }
    :deep(.slide_box .instructor p){
        font-size: 15px;
        line-height: 27px;
        font-weight: 300;
        color: #0E101A;
    }
    .slide_box .step_listing h3 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        padding: 0;
    }
    .slide_box .step_listing ul {
        list-style-type: none;
        max-height: 352px;
        overflow-y: scroll;
        margin-top: 20px;
    }
    .slide_box .step_listing ul::-webkit-scrollbar{
        display: none;
    }
    .slide_box .step_listing .step_card{
        border: 0;
        border-radius: 0;
        padding: 0;
        margin-bottom: 25px;
    }
    .slide_box .step_listing .step_card .items{
        display: flex;
        flex-grow: 1;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        overflow: hidden;
    }
    .slide_box .step_listing .step_card .thumb{
        flex: 0 0 106px;
        position: relative;
    }
    .slide_box .step_listing .step_card .step_info{
        height: 61px;
        padding: 6px 10px;
    }
    .slide_box .step_listing .step_card .step_info h5{
        font-size: 11px;
        line-height: 13px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 3px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .slide_box .step_listing .step_card .step_info p{
        font-size: 9px;
        line-height: 11px;
        font-weight: 400;
        color: #5a5a5a;
        height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .slide_box .step_listing .step_lock{
        border-radius: 5px;
    }
    .slide_box .product_card{
        width: 100%;
        overflow: visible;
        border: 0;
        border-radius: 0;
        margin: 0;
        padding-bottom: 15px;
    }
    .slide_box .product_card h3{
        padding: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .slide_box .progress_sec{
        margin-bottom: 30px;
    }
    .slide_box .progress_sec h3{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .logo_img{
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }

    .saved-style-text {
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: #76797c;
    }
    .progress_status{
        margin-bottom: 10px;
    }
    .progress_status .status{
        height: 8px;
        border-radius: 4px;
        background: #ddd;
        position: relative;
        max-width: 100%;
        overflow: hidden;
    }
    .progress_status .status span{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 0;
        background: var(--var-progress-color);
        z-index: 1;
    }
    .progress_status h5{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        text-align: center;
        padding-top: 8px;
    }

    .toggle_details{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        margin: 0 15px;
        cursor: pointer;
    }
    .global_setting .color_container.show .toggle_details{
        transform: rotate(-180deg);
    }
    @media(max-width: 1199px){
        .global_setting .tabs_content .section_content:after{
            left: 10px;
            right: 10px;
        } 
    }
    @media(max-width: 499px){
        .global_setting .color_container{
            padding: 20px;
        }
        .global_setting .tabs_content .section_content:after{
            left: 0;
            right: 0;
        }
    }
</style>
